@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.primary-color {
  color: rgba(22, 32, 44, 1);
}

.primary-bg {
  background-color: rgba(22, 32, 44, 1);
}

.primary-bg-2 {
  background-color: rgba(36, 59, 122, 1);
}
.navbar-nav li {
  padding: 10px;
  margin-right: 10px;
  margin-left: 5px;
  border-bottom: 3px solid transparent;
}

.navbar-nav li.active {
  color: rgba(238, 151, 29, 1);
  border-bottom-color: rgba(238, 151, 29, 1);
}

.secodary-bg, .secondary-bg {
  background-color: rgba(238, 151, 29, 1);
}

.secondary-bg-2 {
  background-color: rgba(238, 151, 29, 0.5);
  color: rgba(22, 32, 44, 1);

}

.navbar-nav li.active a {
  color: rgba(238, 151, 29, 1);
}

.nav > li > a:hover, .nav > li > a:active, nav > li > a:focus, .aoi > li:nth-child(2n) a:hover {
  text-decoration: none;
  color: rgba(238, 151, 29, 1);
}

/* This is the light theme */

.light {
  background-color: #ffffff;
}

.light .navbar {
  border-bottom: 1px solid #ddd;
  color: #ffffff;
}

.light .navbar a {
  color: #909090;
}

#navbar-collapse-main {
  height: 48px;
}

.secondary-color {
  color: rgba(238, 151, 29, 1);
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: #ffffff !important;
  /* border-right: none; */
  /* border-left: none; */
}

.home-tabs-active {
  font-weight: bold;
}
/* .light .navbar */

.filter {
  color: black;
}

.footer-custom {
  color: whitesmoke;
  border-bottom: 0px solid #fff;
  font-family: arial;
  font-size: 15px;
  background: rgba(238, 151, 29, 1);
}

.bottom-hr {
  border-top: 1px solid whitesmoke;
  opacity: 0.5;
}

.container {
  max-width: 1280px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 3px solid transparent !important;
}

.nav-tabs .nav-link.active {
  border-bottom-color: rgba(238, 151, 29, 1) !important;
  color: rgba(238, 151, 29, 1);
}

.nav-tabs .nav-link {
  color: inherit;
}

.betslip-selector {
  border-bottom: 1px solid #e5e5e5;
}

.betslip-selector .nav > li {
  position: relative;
  display: block;
  margin-right: 25px;
}

.std-input {
  border: 1px solid #f0f0f0;
  background-color: #ddd;
}

.std-block {
  padding: 10px;
}

.carousel-item {
  max-height: 200px;
}

.page-title {
  font-weight: 600;
  color: #777;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
}

.accordion-button:not(.collapsed) {
  color: #666;
  border: none;
}
.accordion-button {
  outline: none;
  border: none;
  background: #f9f9f9 !important;
  /* border-bottom: #505050; */
  padding: 20px;
  font-size: 16px;
  border: none !important;
  /* color: whitesmoke; */
  box-shadow: none !important;
  border-bottom: thin solid white;
}

.accordion-item-panel {
  background: #ffffff !important;
  padding: 10px 35px;
  line-height: 30px;
  color: #333333c9;
  font-size: 16px;
}

.block-panel {
  border: 1px solid #ddd;
}

.btn.secondary-bg:hover {
  background-color: rgba(238, 151, 29, 1);
  opacity: 0.8;
}

.pro-icon img {
  background: #999;
  /* color: black !important; */
}

.small-story h5 {
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: bold;
  margin-top: 10px;
}
.small-story img {

  margin-bottom: 10px;
}

.matches .top-matches button {
  height: 35px;
  /* border-radius: 2px !important; */
  border-radius: 25px;
  background: #e8e8e8;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  
  overflow-y: hidden !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout:hover {
  
  overflow-y: auto !important;
}

.betslip .bottom {
  border-bottom: 1px solid #f0f0f0;
}

.bet-amount {
  /* background-color: #f3f3f3; */
}

.betslip-picks {
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 10px;
}

.selected-teams {
  font-weight: bold;
  color: #374957c7 !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none !important;
  font-weight: bold;
}

.match-detail .top-matches.match {
  padding-right: 0;
  padding-left: 0;
  border-bottom: none !important;
  border-top: none;
}


.dark {
  
}

/* dark theme */
.dark {
  background-color: rgba(22, 32, 44, 1);
  color: rgba(255, 255, 255, .8);
}
  .dark .pro-sidebar > .pro-sidebar-inner {
    background: transparent !important;
    color: rgba(255, 255, 255, 0.7);
    height: 100%;
    position: relative;
    /* z-index: 101; */
    /* border-right: 1px solid rgba(255,255,255, 0.071); */
  }

 
  /* Change the white to any color */
.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover, 
.dark input:-webkit-autofill:focus, 
.dark input:-webkit-autofill:active,
.dark input:autofill,
.dark input:autofill:hover, 
.dark input:autofill:focus, 
.dark input:autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(44, 62, 83) inset !important;
    /* color: black !important; */
    -webkit-text-fill-color: #ffffff !important;
    border: none;

}

.dark .top-matches.event-row {
    border-bottom: 1px solid rgba(255,255,255,0.15);
  }
  .dark .nav-tabs .nav-link:focus, .dark .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: transparent !important;
  }
  .dark .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: rgba(255, 255, 255, 0.5) !important;
    border-radius: 8px;
  }
  .dark .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    /* padding: 0.9px 15px !important; */
    padding: 2px 17px 2px 5px !important;
    /* border-bottom: 1px solid #dddddd; */
    color: rgba(255,255,255, 0.8) !important;
    border-bottom: 1px solid rgba(255,255,255,0.051);
}
.dark .pro-sidebar .pro-sub-menu .pro-menu-item > .pro-inner-item {
    padding: 0.5px 25px 0px 12px !important;
    border-bottom: none;
    color: inherit !important;
  }

  .dark .pro-sidebar .pro-menu .pro-menu-item,
  .dark .pro-sidebar .pro-menu .pro-menu-item:visited,
  .dark .pro-sidebar .pro-menu .pro-menu-item:focus {
    font-size: 15px;
    color: rgba(255,255,255,0.8) !important;
}

.dark .pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #ddd !important;
  }

  .dark .open .react-slidedown {
    /* border-bottom: 1px solid #dddddd; */
    border-top: none;
    border: 1px solid rgba(0,0,0,.1);
  }
  .dark .pro-icon img {
    background: transparent;
    /* color: black !important; */
  }
  .dark .pro-menu-item.open >.pro-inner-item {
    font-weight: bold;
    color: white !important;
  }
  .dark .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .dark .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #d4d4d4 !important;
  }
  .dark .secondary-bg-2 {
    background-color: rgba(28, 117, 188, 0.2) !important;
    color: rgba(255, 255, 255, 1) !important;
  }
  .dark .filter {
    color: inherit;
  }
  .dark .filter option {
    color: black;
  }
  .dark .nav-tabs .nav-link.active {
    border-bottom-color: rgba(238, 151, 29, 1) !important;
    color: rgba(238, 151, 29, 1);
    font-weight: 500;
    background: inherit;
  }

  .dark .profound-text {
    color: rgba(255, 255, 255, 1);
  }
  .dark .top-matches .side {
    color: #ffffff;
}

.dark .page-title {
    border-bottom: 1px solid rgba(255,255,255,0.06);
    /* background: rgba(28, 117, 188, 0.2) !important; */
    color: rgba(255,255,255,0.8);
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
    /* -webkit-box-shadow: 0px 0px 2px rgba(0,0,0,0.2); */
    margin-bottom: 40px;
  }
  .dark .accordion-button {
    outline: none;
    background: rgba(28, 117, 188, 0.2) !important;
    /* border-bottom: #505050; */
    padding: 20px;
    font-size: 16px;
    border: none !important;
    /* color: whitesmoke; */
    box-shadow: none !important;
    border-bottom: thin solid white;
  }
  .dark .accordion-item-panel {
    background: rgba(28, 117, 188, 0.1) !important;
    padding: 10px 35px;
    line-height: 30px;
    color: inherit;
    font-size: 16px;
  }
  .dark .accordion__panel {
    padding: 20px;
    color: inherit !important;
    animation: fadein 0.35s ease-in;
  }
  .dark .accordion-button {
    outline: none;
    background: rgba(28, 117, 188, 0.2) !important;
    /* border-bottom: #505050; */
    padding: 20px;
    font-size: 16px;
    border: none !important;
    color: rgba(255,255,255,0.6);
    box-shadow: none !important;
    border-bottom: thin solid white;
  }
  .dark .block-panel {
    border: 1px solid rgba(255,255,255,0.2);
  }
  .dark .nav-tabs {
    
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
  .dark .std-input {
    border: 1px solid rgba(28, 117, 188, 0.2) !important;
    background-color: rgba(28, 117, 188, 0.2) !important;
  }

  .dark .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
    font-weight: bold;
    color: #fff;
  }
  .dark .bet-option-list li {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    background: transparent;
    padding-right: 20px !important;
    position: relative;
    color: #000;
    padding: 4px;
    text-shadow: none;
    border-bottom: 1px dashed #ddd;
    overflow: hidden;
    line-height: 1.6;
}
.dark .bet-option-list li:last-child {
  border-bottom: none;
}
.dark .bet-cancel {
  /* float: right; */
  /* width: 10%; */
  position: absolute;
  font-size: 18px;
  right: 7px;
  top: 13%;
  color: red;
}
  .dark .selected-teams {
    font-weight: 500;
    color: #000 !important;
}
.dark .bet-pick {
  font-family: 'Montserrat', sans-serif;
  float: left;
  margin-top: 5px;
  width: 100%;
  color: #000;
}
  .dark .betslip-picks {
    border: none;
    border-radius: 10px;
    padding: 0px;
  }
  .dark .bet-amount {
    /* background-color: #23313d; */
  }
  .dark #betting input {
    /* max-width: 100px; */
    width: 100%;
    padding: 3px;
    height: 35px;
    background: #fff !important;
    border-color: #333 !important;
    color: #333 !important;
    box-shadow: none !important;
    border-radius: 5.2px;
    -webkit-text-fill-color: #333 !important;
    border: 1px solid #666 !important;
    text-align: center;
}

.bg-white-02 {
  background-color: rgba(255,255,255,0.2);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  cursor: not-allowed !important;
  background-color: rgba(238, 151, 29, 1);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: 0.5;
}

.pro-sidebar > .pro-sidebar-inner {
	background: #1d1d1d;
	height: 100%;
	position: relative;
	z-index: 101;
}

.pro-sidebar > .pro-sidebar-inner {
	background: transparent !important;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover, 
.dark input:-webkit-autofill:focus, 
.dark input:-webkit-autofill:active,
.dark input:autofill,
.dark input:autofill:hover,
.dark  input,
.dark input:focus,

.dark input:autofill:focus, 
.dark input:autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(44, 62, 83) inset !important;
    color: #ffffff !important;
    -webkit-text-fill-color: #ffffff !important;
    border: none;

}

.dark .pro-sidebar > .pro-sidebar-inner {
  background: rgba(28, 117, 188, 0.2) !important;
  color: rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(255,255,255,0.051);
}
.dark .pro-sidebar .pro-menu .pro-menu-item,
.dark .pro-sidebar .pro-menu .pro-menu-item:visited,
.dark .pro-sidebar .pro-menu .pro-menu-item:focus {
    font-size: 15px;
    color: rgba(255,255,255,0.8) !important;
}

.dark .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 8px;
}

.betslip-v2 {
  font-family: "Open Sans";
  font-size: 12px;
  margin-bottom: 30px;
  background-color: #1d323b;
  position: relative;
}
.betslip-v2 .betslip-header {
  padding: 8px;
  background-color: #0F4357;
  color: #FFFFFF;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  z-index: 4;
}

.betslip-v2 .betslip-content {
  background-color: #fff;
  color: #070707;
  /* padding: 0 8px; */
}

.betslip-v2 .betslip-footer {
  background-color: #0F4357;
  color: #ffffff;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.dark .btn-light-secondary {
  background: rgba(255,255,255,0.2);
  color: #fff;
}

.dark .bet-btn {
  min-width: 75% !important;
  /* width: 75%; */
}
.btn-place-bet {

}
.btn-place-bet {
  display: block;
  text-transform: uppercase;
  background-color: #fff;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #C5DAEA 100%);
  box-shadow: 0 2px 4px 0 rgba(22, 39, 48, 0.5);
  border: 1px solid rgba(26, 35, 126, 0.13);
  border-radius: 5px;
  transition: all 0.25s;
  color: #0F4357 !important;
  font-size: 1.2em;
  font-weight: 800;
}
.btn-place-bet:hover {
  opacity: 0.8;
}
.bet-table {
  /* border-top: 1px solid #dddddd; */
}

.bet-table-divider {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dddddd;
}

.paybillnumbers {
    position: relative;
    margin-top: -15px;
    background: rgba(28, 117, 188, 0.3) !important;
    background-position: -20% 20%;
    background-size: 100%;
    margin-bottom: 16px;
    padding-bottom: 0px;
    text-align: center;
    z-index: 1;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
}
.paybillnumbers h2 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.8px;
  padding-top: 15px;
  padding-bottom: 5px;
}
.paybillnumbers p {
  font-size: 12px;
  padding: 8px;
}

.paybillnumbers ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.07);
}
.paybillnumbers ul li span {
  padding: 6px 12px;
  font-weight: 700;
}

.mybet-item {

}
.mybet-item {
  background-color: rgba(28, 117, 188, 0.2) !important;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}

.dark .bet-item {
  color: #ffffff;
}

.dark .slipheader {
  background: #26323C !important;
  padding: 5px;
  font-weight: bold;
  font-size: 12px;
  opacity: 1;
  margin-bottom: 1px;
}

.dark .kumbafu {
  margin: 0px 0px 1px 0px !important;
  border-bottom: 1px solid #00000003;
  width: 100%;
  opacity: 1 !important;
  display: flex;
  background-color: #26323C;
  background: #26323C !important;
  margin-bottom: 2px;
  padding: 10px;
}

.kumbafu:last-child {
  border-bottom: 1px solid transparent !important;
}
.bethistory .bet-details .bet-details-body .bet-detail {
  display: flex;
  background-color: #26323C;
  margin-bottom: 2px;
  padding: 10px;
}
.accordion__button {
  background-color: transparent;
  color: #444;
  cursor: pointer;
  padding: 3px;
  width: 100%;
  text-align: left;
  border: none;
}
.accordion__button:hover {
  background-color: transparent;
  opacity: 0.6;
}