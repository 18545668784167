/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    color: #808080;
    display: inline-flex;
}

.accordion__button:hover {
    background-color: #18242f;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid #1e2d3b;
    border-right: 2px solid #1e2d3b;
    transform: rotate(-45deg);
    margin: 15px 15px;
    background: #1e2d3b;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 0px;
    color:#fff;
    animation: fadein 0.35s ease-in;
}
.kumbafu {
    margin: 0px 0px 1px 0px;
    width: 100%;
    padding: 5px;
    opacity: 0.8;
}
.slipheader {
    background: #264e5f;
    padding: 5px;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.8;
    margin-bottom: 1px;
}
/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

